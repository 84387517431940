<script setup lang="ts">
const emit = defineEmits(['update:page'])
const props = defineProps<{
  page: number
  max: number
}>()

const { t } = useI18n()

const current = computed({
  get: () => props.page,
  set: (value) => emit('update:page', value),
})

const isTablet = useMediaQuery('(min-width: 768px)')
const maxPages = computed(() => (isTablet.value ? 4 : 2))

// Calculate which page numbers to show
const pages = computed(() => {
  const totalPages = props.max
  const currentPage = current.value
  const maxVisible = maxPages.value
  const pages: number[] = []

  // Always show first page
  pages.push(1)

  let start = Math.max(2, currentPage - Math.floor(maxVisible / 2))
  let end = Math.min(totalPages - 1, start + maxVisible - 1)

  // Adjust start if we're near the end
  if (end === totalPages - 1) {
    start = Math.max(2, end - maxVisible + 1)
  }

  // Add ellipsis after first page if needed
  if (start > 2) {
    pages.push(-1) // -1 represents ellipsis
  }

  // Add middle pages
  for (let i = start; i <= end; i++) {
    pages.push(i)
  }

  // Add ellipsis before last page if needed
  if (end < totalPages - 1) {
    pages.push(-1) // -1 represents ellipsis
  }

  // Always show last page if there is more than one page
  if (totalPages > 1) {
    pages.push(totalPages)
  }

  return pages
})

const next = () => {
  if (current.value === props.max) return
  current.value += 1
}

const previous = () => {
  if (current.value === 1) return
  current.value -= 1
}

const goToPage = (page: number) => {
  if (page === -1 || page === current.value) return // Don't handle ellipsis clicks
  current.value = page
}
</script>

<template>
  <nav class="pagination" aria-label="Pagination">
    <ClientOnly>
      <div class="pagination__content">
        <button
          v-for="p in pages"
          :key="p"
          class="pagination__btn"
          :class="{
            'pagination__btn--active': p === current,
            'pagination__btn--ellipsis': p === -1,
          }"
          :aria-label="p === -1 ? undefined : t('pagination.pageLabel', { page: p })"
          :aria-current="p === current ? 'true' : undefined"
          :disabled="p === -1"
          @click="goToPage(p)"
        >
          {{ p === -1 ? '…' : p }}
        </button>
      </div>
    </ClientOnly>
    <div class="navigation">
      <ButtonNavigation :disabled="current === 1" :aria-label="t('pagination.previousPage')" @click="previous" />
      <ButtonNavigation
        direction="right"
        :disabled="current === props.max"
        :aria-label="t('pagination.nextPage')"
        @click="next"
      />
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  color: var(--c-primary);

  &__content {
    display: flex;
    align-items: center;
  }

  &__btn {
    @include fluid-ts-ds(--size, 42px, 54px);
    @include fluid-ts-ds(--font-size, 11px, 14px);
    width: var(--size);
    height: var(--size);
    border: 1px solid transparent;
    background: transparent;
    color: currentColor;
    cursor: pointer;
    font-size: var(--font-size);
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include transitions(border-color, border-width, transform);

    &:not(:disabled):hover {
      border-width: 2px;
      border-color: var(--c-blue-rollover);
      color: var(--c-blue-rollover);
      transform: scale(0.92);
    }

    &--active {
      border-color: var(--c-primary);
      pointer-events: none;
    }

    &--ellipsis {
      cursor: default;

      &:hover {
        transform: none;
      }
    }
  }
}

.navigation {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
</style>
